import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import All from "./All";
import Approved from "./Approved";
import Rejected from "./Rejected";
import Unapproved from "./Unapproved";
import Cancelled from "./Cancelled";
import { Select } from "../../../controls";
import ShowNextYearLeaveDetail from "./ShowNextYearLeaveDetail";
import ExpiredRequest from "./ExpiredRequest";

const MyLeaveApplications = ({
  myTeamEmployeeLeaveList,
  getMyTeamEmployeeLeaveList,
  user,
  initDoc,
  getDocument,
  userDocument,

  updateLeave,
  downloadFile,
  defaultTabActiveKey,
  onsetLeaveId,
  leaveId,
  showNextYearLeave,
  onsetDate,
  getNextYearLeaveDependOnId,

  showLeaveById,
  leaveById,
  closeLeaveByIdData,
  currentYear,
  leaveMasterTypeList,
  leaveTypesList,
  updateLeaveDetail,
  getEmployeeLeavesSummary,
  employeeLeavesSummary,
}) => {
  // console.log("myTeamEmployeeLeaveList", myTeamEmployeeLeaveList);
  const yearList = [
    {
      value: new Date().getFullYear() - 1,
      label: `${new Date().getFullYear() - 1}`,
    },
    {
      value: new Date().getFullYear(),
      label: `${new Date().getFullYear()}`,
    },
    {
      value: new Date().getFullYear() + 1,
      label: `${new Date().getFullYear() + 1}`,
    },
  ];
  return (
    <div className="card">
      <div className="card-header border-0 mb-3">
        <h5 className="card-title">Leave Application(s)</h5>
        {/* <ButtonView
          onClick={() => {
            if (showNextYearLeave) {
              getMyTeamEmployeeLeaveList(true, false);
            } else {
              getMyTeamEmployeeLeaveList(true, true);
            }
            onsetDate(new Date().toISOString().split("T")[0]);
          }}
          title={
            showNextYearLeave
              ? "View Current Year Leave"
              : "View Next Year Leave"
          }
          styles="secondary"
        /> */}
        <div style={{ width: "150px" }}>
          <Select
            name="year"
            // label="Select Year"
            isSearchable={false}
            defaultValue={yearList.find((list) => currentYear === list.value)}
            placeholder="Select"
            options={yearList}
            onChange={(val) => {
              getMyTeamEmployeeLeaveList(true, val.value);

              onsetDate(new Date().toISOString().split("T")[0]);
            }}
            className="select"
            classNamePrefix="select"
          />
        </div>
      </div>
      <Tabs
        defaultActiveKey={defaultTabActiveKey}
        id="leave_application"
        className="table_tabs"
      >
        <Tab
          eventKey="pendingapproval"
          title={
            <span className="notification_container">
              PENDING APPROVAL
              {myTeamEmployeeLeaveList
                .filter((list) => list.status === 0)
                .filter((item) => {
                  var now = new Date();
                  now.setHours(0, 0, 0, 0);
                  if (new Date(item.startDate) < now) {
                    return false;
                  } else return true;
                }).length ? (
                <div className="notification_dots"></div>
              ) : null}
            </span>
          }
        >
          <Unapproved
            data={myTeamEmployeeLeaveList.filter((list) => list.status === 0)}
            // cancelLeave={cancelLeave}
            user={user}
            initDoc={initDoc}
            getDocument={getDocument}
            userDocument={userDocument}
            updateLeave={updateLeave}
            showNextYearLeave={showNextYearLeave}
            downloadFile={downloadFile}
            onsetLeaveId={onsetLeaveId}
            leaveId={leaveId}
            getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
            currentYear={currentYear}
            leaveMasterTypeList={leaveMasterTypeList}
            leaveTypesList={leaveTypesList}
            updateLeaveDetail={updateLeaveDetail}
            getEmployeeLeavesSummary={getEmployeeLeavesSummary}
            employeeLeavesSummary={employeeLeavesSummary}
          />
        </Tab>
        <Tab eventKey="expired" title="AVAILED">
          <ExpiredRequest
            data={myTeamEmployeeLeaveList.filter((list) => list.status === 1)}
            // cancelLeave={cancelLeave}
            user={user}
            initDoc={initDoc}
            getDocument={getDocument}
            userDocument={userDocument}
            updateLeave={updateLeave}
            showNextYearLeave={showNextYearLeave}
            downloadFile={downloadFile}
            onsetLeaveId={onsetLeaveId}
            leaveId={leaveId}
            getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
            currentYear={currentYear}
            leaveMasterTypeList={leaveMasterTypeList}
            leaveTypesList={leaveTypesList}
            updateLeaveDetail={updateLeaveDetail}
          />
        </Tab>
        <Tab eventKey="approved" title="APPROVED">
          <Approved
            data={myTeamEmployeeLeaveList.filter((list) => list.status === 1)}
            // cancelLeave={cancelLeave}
            user={user}
            initDoc={initDoc}
            getDocument={getDocument}
            userDocument={userDocument}
            updateLeave={updateLeave}
            showNextYearLeave={showNextYearLeave}
            downloadFile={downloadFile}
            currentYear={currentYear}
            onsetLeaveId={onsetLeaveId}
            leaveId={leaveId}
            getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
            leaveMasterTypeList={leaveMasterTypeList}
            leaveTypesList={leaveTypesList}
            updateLeaveDetail={updateLeaveDetail}
          />
        </Tab>
        <Tab eventKey="rejected" title="REJECTED">
          <Rejected
            data={myTeamEmployeeLeaveList.filter((list) => list.status === -1)}
            // cancelLeave={cancelLeave}
            user={user}
            initDoc={initDoc}
            getDocument={getDocument}
            userDocument={userDocument}
            updateLeave={updateLeave}
            showNextYearLeave={showNextYearLeave}
            downloadFile={downloadFile}
            currentYear={currentYear}
          />
        </Tab>
        <Tab eventKey="cancelled" title="CANCELLED">
          <Cancelled
            data={myTeamEmployeeLeaveList.filter((list) => list.status === -2)}
            // cancelLeave={cancelLeave}
            initDoc={initDoc}
            getDocument={getDocument}
            userDocument={userDocument}
            downloadFile={downloadFile}
            onsetLeaveId={onsetLeaveId}
            leaveId={leaveId}
            getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
          />
        </Tab>
        <Tab eventKey="all" title="ALL">
          <All
            data={myTeamEmployeeLeaveList}
            user={user}
            initDoc={initDoc}
            getDocument={getDocument}
            userDocument={userDocument}
            downloadFile={downloadFile}
          />
        </Tab>
      </Tabs>

      <ShowNextYearLeaveDetail
        leaveDependOnId={leaveById}
        showLeaveById={showLeaveById}
        closeLeaveByIdData={closeLeaveByIdData}
        user={user}
        initDoc={initDoc}
        getDocument={getDocument}
        userDocument={userDocument}
        updateLeave={updateLeave}
        showNextYearLeave={showNextYearLeave}
        downloadFile={downloadFile}
        currentYear={currentYear}
      />
    </div>
  );
};

export default MyLeaveApplications;
