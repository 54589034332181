import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { ButtonView, Input, Select, TextArea } from "../../../controls";
import moment from "moment";
// import OptionalLeaveFormModal from "./OptionalLeaveFormModal";

const data = {
  leaveSubType: "",
  leaveType: "",
  startDate: "",
  endDate: "",
  comments: "",
  masterLeaveType: "",
};

const leaveSubTypesListOptions = [
  { value: "Full Day", label: "Full Day" },
  { value: "Half Day - 1st Half", label: "Half Day - 1st Half" },
  { value: "Half Day - 2nd Half", label: "Half Day - 2nd Half" },
];

const LeaveFormModal = ({
  user,
  leaveTypesList,
  userManagerList,
  applyForLeave,
  leaveMasterTypeList,
  empData,
  getemployeeLeaveList,
  showNextYearLeave,
  getOtherLeavesSummary,
  getEmployeeLeavesSummary,
  currentYear,
  onsetDate,
  getHolidays,
  holidaysList,

  canApplyOptionalHolidayLeave,
  getOptionalHolidayLeaveSummary,
  canApply,
}) => {
  // get manager name from managerList
  // console.log("empData", empData);
  // console.log(leaveMasterTypeList);
  const yearList = [
    {
      value: new Date().getFullYear() - 1,
      label: `${new Date().getFullYear() - 1}`,
    },
    {
      value: new Date().getFullYear(),
      label: `${new Date().getFullYear()}`,
    },
    {
      value: new Date().getFullYear() + 1,
      label: `${new Date().getFullYear() + 1}`,
    },
  ];

  const approverName = userManagerList
    .map((list) => {
      return {
        name: `${list.userId[0].firstName} ${list.userId[0].lastName}`,
        id: list._id,
        email: list.userId[0].email,
      };
    })
    .find((i) => i.id === user.reportingManagerEmployeeId);

  // Get master leave typefirst
  const masterLeaveTypeOptions = leaveMasterTypeList.map((leaveType) => {
    return { value: leaveType.description, label: leaveType.description };
  });

  const [leaveData, setLeaveData] = useState(data);
  const [applyForLeaveShow, setApplyForLeaveShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [leaveTypesListOptions, setLeaveTypesListOptions] = useState([]);
  const [leaveTypeValue, setLeaveTypeValue] = useState(null);
  const [file, setFile] = useState(null);

  const [optionalHolYear, setOptionalHolYear] = useState("");
  const [optionalHolidayList, setOptionalHolidayList] = useState([]);
  const [optionalHolidayValue, setOptionalHolidayValue] = useState(null);
  // console.log("leavedata", leaveData);
  useEffect(() => {
    if (optionalHolYear) {
      setOptionalHolidayList(null);
      setOptionalHolidayValue(null);
      const newOptionalHolidayList = holidaysList
        .filter((list) => list.year === optionalHolYear)
        .map((list) => {
          return {
            label: `${list.description} (${moment(list.date).format(
              "MMMM Do YYYY"
            )})`,
            value: list.date,
          };
        });
      setOptionalHolidayList(newOptionalHolidayList);
    }
  }, [optionalHolYear, setOptionalHolidayList, holidaysList]);

  const onLoadFile = (e) => {
    // console.log("e", e);
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  // console.log(leaveData);

  const handleOpen = () => {
    setApplyForLeaveShow(true);
  };

  const handleSubmit = () => {
    var rules = {
      required: [
        "leaveSubType",
        "leaveType",
        "startDate",
        "endDate",
        "masterLeaveType",
        "comments",
      ],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(leaveData),
    });

    if (leaveData.masterLeaveType === "Optional Holiday Leave") {
      if (!optionalHolYear) {
        errors = {
          ...errors,
          optionalHolYear: "Please fill required field",
        };
      }
    }
    setErrors(errors);

    if (!Object.keys(errors).length) {
      // get reject start date
      // const rejectStartDate = empData.filter((list) => list.status === -1).map(e => e.startDate.slice(0, 10))

      const getDaysArray = (start, end) => {
        // console.log("start", start);
        for (
          var arr = [], dt = new Date(start);
          dt <= end;
          dt.setDate(dt.getDate() + 1)
        ) {
          arr.push(new Date(dt));
        }
        return arr;
      };

      // get approved and pending leaves list
      const approvedAndPendingLeaveList = empData.filter(
        (list) => list.status !== -1
      );

      var dateArr = [];
      approvedAndPendingLeaveList.forEach((list) => {
        var daylist = getDaysArray(
          new Date(list.startDate),
          new Date(list.endDate)
        );
        // console.log("daylist", daylist);
        dateArr = [
          ...dateArr,
          ...daylist.map((v) => v.toISOString().slice(0, 10)),
        ];
      });

      const newDateArr = [...dateArr];
      // const newDateArr = [...dateArr, ...rejectStartDate]

      if (
        leaveData.startDate &&
        newDateArr.indexOf(leaveData.startDate) === -1 &&
        leaveData.endDate &&
        newDateArr.indexOf(leaveData.endDate) === -1
      ) {
        var now = new Date(Date.now() - 12096e5);
        console.log("now", now);
        now.setHours(0, 0, 0, 0);
        if (new Date(leaveData.startDate) > now) {
          var startDate = new Date(leaveData.startDate);
          startDate.setHours(0, 0, 0, 0);

          console.log("startdate", startDate);
          if (new Date(leaveData.endDate) > startDate) {
            // console.log(leaveData);
            if (
              new Date(leaveData.startDate).getFullYear() ===
              new Date(leaveData.endDate).getFullYear()
            ) {
              if (leaveData.masterLeaveType === "Optional Holiday Leave") {
                if (canApply) {
                  // const currentDate = new Date();
                  // const optHolidayDate = new Date(leaveData.startDate);

                  // const oneDay = 1000 * 60 * 60 * 24;

                  // // Calculating the time difference between two dates
                  // const diffInTime =
                  //   optHolidayDate.getTime() - currentDate.getTime();

                  // // Calculating the no. of days between two dates
                  // const diffInDays = Math.round(diffInTime / oneDay) + 1;

                  // // console.log(diffInDays);
                  // if (diffInDays >= 14) {
                  //   // console.log(leaveData);
                  //   applyForLeave(
                  //     leaveData,
                  //     file,
                  //     user,
                  //     approverName,
                  //     currentYear
                  //   );
                  //   handleClose();
                  // } else {
                  //   alert(
                  //     "Optional holiday leave must be apply two weeks prior to the festival date."
                  //   );
                  // }
                  applyForLeave(
                    leaveData,
                    file,
                    user,
                    approverName,
                    currentYear
                  );
                  handleClose();
                } else {
                  alert(
                    "You have already applied for two optional holidays this year."
                  );
                  // if(leaveData.startDate )
                }
              } else {
                // console.log(leaveData);
                // console.log("hi");
                applyForLeave(leaveData, file, user, approverName, currentYear);
                handleClose();
              }
            } else {
              alert(
                "Start and end year of the leave should be same. To apply for next year leave please choose proper month of the next year."
              );
            }
          } else {
            alert("Invalid end date");
          }
        } else {
          alert(
            "Invalid date. Past date should be less than 14 days from current day"
          );
        }
      } else {
        alert(
          `Application already applied for any date between ${moment(
            new Date(leaveData.startDate)
          ).format("LL")} - ${moment(new Date(leaveData.endDate)).format(
            "LL"
          )}.`
        );
      }
    }
  };

  const handleClose = () => {
    setApplyForLeaveShow(false);
    setLeaveData(data);
    setFile(null);
    setErrors({});
    setLeaveTypesListOptions([]);
    setLeaveTypeValue(null);
    setOptionalHolYear("");
    setOptionalHolidayValue(null);
  };

  return (
    <>
      <div className="card-header d-flex border-0 mb-3">
        <h5 className="card-title">My Leave Application(s)</h5>
        <div className="d-flex flex-wrap">
          <div style={{ marginTop: "6px" }} className="me-2">
            <ButtonView
              onClick={handleOpen}
              title={"Apply for Leave"}
              styles="secondary"
            />
          </div>
          <div style={{ width: "150px" }}>
            <Select
              name="year"
              // label="Select Year"
              isSearchable={false}
              defaultValue={yearList.find((list) => currentYear === list.value)}
              placeholder="Select"
              options={yearList}
              onChange={(val) => {
                console.log("val", val);
                getemployeeLeaveList(true, val.value);
                getOtherLeavesSummary(true, val.value);
                getEmployeeLeavesSummary(true, val.value);
                getOptionalHolidayLeaveSummary(true, val.value);
                onsetDate(new Date().toISOString().split("T")[0]);
              }}
              className="select"
              classNamePrefix="select"
            />
          </div>
        </div>
      </div>
      <Modal
        show={applyForLeaveShow}
        onHide={handleClose}
        aria-labelledby="filter_modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title id="filter_modal">Leave Application</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Select
                name="leaveSubType"
                label="FD/1H/2H"
                isSearchable={false}
                // defaultValue={roleOptions.find(option => option.value === userData.role)}
                placeholder="Select"
                options={leaveSubTypesListOptions}
                onChange={(val) =>
                  setLeaveData({
                    ...leaveData,
                    leaveSubType: val.value,
                    endDate: "",
                    startDate: "",
                  })
                }
                className="select"
                classNamePrefix="select"
                error={errors["leaveSubType"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="approver"
                label="Approver"
                value={approverName.name ? approverName.name : ""}
                type="text"
                placeholder="Approver"
                readOnly
                size="fluid"
                // error={errors["lastName"]}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Select
                name="masterLeaveType"
                label="Leave Type"
                isSearchable={false}
                // defaultValue={masterLeaveTypeOptions[0]}
                placeholder="Select Leave Type"
                options={masterLeaveTypeOptions}
                onChange={(val) => {
                  setLeaveData({
                    ...leaveData,
                    masterLeaveType: val.value,
                    leaveType: "",
                    endDate: "",
                    startDate: "",
                  });
                  // setShowList(false)
                  setLeaveTypeValue(null);
                  const newMasterLeave = leaveTypesList.filter((list) => {
                    return list.masterLeaveType.description === val.value;
                  });

                  setLeaveTypesListOptions(
                    newMasterLeave.map((leaveType) => {
                      return {
                        value: leaveType._id,
                        label: leaveType.description,
                      };
                    })
                  );
                  // setTimeout(() => {
                  //   setShowList(true)
                  // }, 300)
                }}
                className="select"
                classNamePrefix="select"
                error={errors["masterLeaveType"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Select
                name="leaveType"
                label="Leave Sub Type"
                isSearchable={false}
                value={leaveTypeValue}
                placeholder="Select Sub Leave Type"
                options={leaveTypesListOptions}
                onChange={(val) => {
                  setLeaveTypeValue(val);
                  setLeaveData({ ...leaveData, leaveType: val.value });
                }}
                className="select"
                classNamePrefix="select"
                error={errors["leaveType"]}
                isrequired={true}
              />
            </Col>
            {leaveData.masterLeaveType === "Optional Holiday Leave" && (
              <>
                <Col md={6} lg={2} className="mb-3">
                  <Select
                    name="optionalLeave"
                    label="Select Year"
                    isSearchable={false}
                    placeholder="Year"
                    options={yearList}
                    onChange={(val) => {
                      canApplyOptionalHolidayLeave(val.value);
                      setOptionalHolYear(val.value);
                      setLeaveData({
                        ...leaveData,
                        endDate: "",
                        startDate: "",
                      });
                      // setLeaveData({
                      //   ...leaveData,
                      //   endDate: val.value,
                      //   startDate: val.value,
                      // });
                    }}
                    className="select"
                    classNamePrefix="select"
                    error={errors["optionalHolYear"]}
                    isrequired={true}
                  />
                </Col>
                <Col md={6} lg={4} className="mb-3">
                  <Select
                    name="optionalLeave"
                    label="Select Optional Holiday"
                    isSearchable={false}
                    value={optionalHolidayValue}
                    placeholder="Optional Holiday"
                    options={optionalHolidayList}
                    onChange={(val) => {
                      setOptionalHolidayValue(val);
                      setLeaveData({
                        ...leaveData,
                        endDate: val.value,
                        startDate: val.value,
                      });
                    }}
                    className="select"
                    classNamePrefix="select"
                    error={errors["startDate"]}
                    isrequired={true}
                  />
                </Col>
              </>
            )}
            {(leaveData.leaveSubType === "Half Day - 1st Half" ||
              leaveData.leaveSubType === "Half Day - 2nd Half") &&
              leaveData.masterLeaveType !== "Optional Holiday Leave" && (
                <>
                  <Col md={12} lg={6} className="mb-3">
                    <Input
                      name="startDate"
                      label="Select Date"
                      value={leaveData.startDate}
                      type="date"
                      onChange={(e) =>
                        setLeaveData({
                          ...leaveData,
                          startDate: e.target.value,
                          endDate: e.target.value,
                        })
                      }
                      size="fluid"
                      error={errors["startDate"]}
                      // min={moment(new Date()).format("YYYY-MM-DD")}
                      isrequired={true}
                    />
                  </Col>
                  <Col md={12} lg={6} className="mb-3">
                    <Input
                      name="endDate"
                      label="End Date"
                      // value={leaveData.endDate}
                      type="date"
                      onChange={(e) =>
                        setLeaveData({
                          ...leaveData,
                          endDate: e.target.value,
                          startDate: e.target.value,
                        })
                      }
                      size="fluid"
                      min={leaveData.startDate}
                      error={errors["endDate"]}
                      // isrequired={true}
                      disabled
                    />
                  </Col>
                </>
              )}
            {
              // (!leaveData.leaveSubType ||
              //   (leaveData.leaveSubType === "Full Day" &&
              //     leaveData.masterLeaveType !== "Optional Holiday Leave")) &&
              // ----- //
              // leaveData.leaveSubType === "Full Day" &&
              //   leaveData.masterLeaveType !== "Optional Holiday Leave" &&
              (!leaveData.leaveSubType ||
                (leaveData.leaveSubType === "Full Day" &&
                  leaveData.masterLeaveType !== "Optional Holiday Leave")) && (
                <>
                  {leaveData.masterLeaveType !== "Optional Holiday Leave" && (
                    <>
                      <Col md={12} lg={6} className="mb-3">
                        <Input
                          name="startDate"
                          label="Start Date"
                          value={leaveData.startDate}
                          type="date"
                          onChange={(e) =>
                            setLeaveData({
                              ...leaveData,
                              startDate: e.target.value,
                            })
                          }
                          size="fluid"
                          // min={moment(new Date()).format("YYYY-MM-DD")}
                          error={errors["startDate"]}
                          isrequired={true}
                        />
                      </Col>
                      <Col md={12} lg={6} className="mb-3">
                        <Input
                          name="endDate"
                          label="End Date"
                          value={leaveData.endDate}
                          type="date"
                          onChange={(e) =>
                            setLeaveData({
                              ...leaveData,
                              endDate: e.target.value,
                            })
                          }
                          size="fluid"
                          min={leaveData.startDate}
                          error={errors["endDate"]}
                          isrequired={true}
                        />
                      </Col>
                    </>
                  )}
                </>
              )
            }

            <Col md={12} lg={6} className="mb-3">
              <Input
                name="file"
                label="Upload File"
                type="file"
                placeholder="Upload File"
                onChange={onLoadFile}
                size="fluid"
                accept=".pdf,.png,.jpg,.doc,.docx"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <TextArea
                name="comments"
                label="Leave Reason"
                value={leaveData.comments}
                type="textarea"
                placeholder="Type something..."
                onChange={(e) =>
                  setLeaveData({ ...leaveData, comments: e.target.value })
                }
                size="fluid"
                error={errors["comments"]}
                isrequired={true}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                onClick={() => {
                  handleClose();
                }}
                title={"Cancel"}
                styles="danger-outline"
              />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                onClick={handleSubmit}
                title={"Apply"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaveFormModal;
