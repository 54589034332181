import React, { Component } from "react";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import "./LeaveApplicationsStyles.scss";
import LeaveApplicationsView from "./LeaveApplicationsView";
import {
  myTeamEmployeeLeaveList as getMyTeamEmployeeLeaveList,
  updateLeave,
  getNextYearLeaveDependOnId,
  closeLeaveByIdData,
  getLeaveMasterTypesList,
  updateLeaveDetail,
} from "../../../store/employeeLeave/employeeLeaveActions";
import { getEmployeeLeavesSummary } from "../../../store/leaveReporting/leaveReporingActions";
import { list as getLeaveTypes } from "../../../store/leaveTypes/leaveTypesActions";
import { list as getHolidays } from "../../../store/holidays/holidaysActions";
import {
  user as getUser,
  initDoc,
  getDocument,
  downloadFile,
} from "../../../store/user/userActions";
import queryString from "query-string";
// import { globals } from "../../../store/globals";
import { Loader } from "../../common";

export class LeaveApplicationsContainer extends Component {
  leaveId = 0;
  state = {
    date: new Date().toISOString().split("T")[0],
    combineCalenderData: [],
    desData: [],
    defaultTabActiveKey: "",
  };

  constructor(props) {
    super(props);
    if (window.location.search !== "") {
      const parsed = queryString.parse(window.location.search);
      this.leaveId = parsed.id;
    }

    this.props.getMyTeamEmployeeLeaveList(
      true,
      new Date().getFullYear(),
      (response) => {
        //when manager view leave application direct from/through email
        if (response) {
          const employeeDataByLeaveId = response?.find((list) => {
            return list._id === this.leaveId;
          });
          if (employeeDataByLeaveId) {
            const data = {
              employeeId: employeeDataByLeaveId?.employeeId[0],
              year: new Date().getFullYear(),
            };
            this.props.getEmployeeLeavesSummary(data);
          }
        }
      }
    );
    this.props.getUser();
    this.props.getHolidays();
    this.props.initDoc();
    this.props.getLeaveMasterTypesList();
    this.props.getLeaveTypes();
    this.srollToSummary = React.createRef();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onsetCombineCalenderList = (data) => {
    this.setState({
      combineCalenderData: data,
    });
  };
  onsetDate = (data) => {
    this.setState({
      date: data,
    });
  };
  onsetDescription = (data) => {
    this.setState({
      desData: data,
    });
  };
  onsetDefaultTabActiveKey = (data) => {
    this.setState({
      defaultTabActiveKey: data,
    });
  };

  onsetLeaveId = (data) => {
    this.leaveId = data;
  };

  render() {
    const {
      myTeamEmployeeLeaveListSuccess,
      myTeamEmployeeLeaveList,
      getMyTeamEmployeeLeaveList,

      userSuccess,
      user,
      initDoc,
      getDocument,
      userDocument,
      downloadFile,

      holidaysSuccess,
      holidaysList,

      updateLeave,
      showNextYearLeave,
      getNextYearLeaveDependOnId,

      showLeaveById,
      leaveById,
      closeLeaveByIdData,

      currentYear,
      leaveTypesList,
      leaveMasterTypeList,
      updateLeaveDetail,

      getEmployeeLeavesSummary,
      employeeLeavesSummary,
    } = this.props;
    // console.log('team',myTeamEmployeeLeaveList)
    if (
      myTeamEmployeeLeaveListSuccess === false ||
      userSuccess === false ||
      holidaysSuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <LeaveApplicationsView
          myTeamEmployeeLeaveList={myTeamEmployeeLeaveList}
          user={user}
          initDoc={initDoc}
          getDocument={getDocument}
          userDocument={userDocument}
          holidaysList={holidaysList}
          onsetCombineCalenderList={this.onsetCombineCalenderList}
          onsetDate={this.onsetDate}
          onsetDescription={this.onsetDescription}
          date={this.state.date}
          desData={this.state.desData}
          combineCalenderData={this.state.combineCalenderData}
          updateLeave={updateLeave}
          downloadFile={downloadFile}
          leaveId={this.leaveId}
          defaultTabActiveKey={this.state.defaultTabActiveKey}
          onsetDefaultTabActiveKey={this.onsetDefaultTabActiveKey}
          onsetLeaveId={this.onsetLeaveId}
          srollToSummary={this.srollToSummary}
          getMyTeamEmployeeLeaveList={getMyTeamEmployeeLeaveList}
          showNextYearLeave={showNextYearLeave}
          getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
          leaveById={leaveById}
          showLeaveById={showLeaveById}
          closeLeaveByIdData={closeLeaveByIdData}
          currentYear={currentYear}
          leaveTypesList={leaveTypesList}
          leaveMasterTypeList={leaveMasterTypeList}
          updateLeaveDetail={updateLeaveDetail}
          employeeLeavesSummary={employeeLeavesSummary}
          getEmployeeLeavesSummary={getEmployeeLeavesSummary}
        />
      );
    }
  }
}

LeaveApplicationsContainer.propTypes = {};

const mapStateToProps = (state) => {
  // console.log("state",state.user.userDocument)
  return {
    leaveById: state.employeeLeave.leaveById,
    showLeaveById: state.employeeLeave.showLeaveById,

    myTeamEmployeeLeaveList: state.employeeLeave.myTeamEmployeeLeaveList,
    showNextYearLeave: state.employeeLeave.showNextYearLeave,
    myTeamEmployeeLeaveListSuccess:
      state.employeeLeave.myTeamEmployeeLeaveListSuccess,

    user: state.user.user,
    userSuccess: state.user.userSuccess,
    userDocument: state.user.userDocument,

    holidaysSuccess: state.holidays.holidaysSuccess,
    holidaysList: state.holidays.holidaysList,

    currentYear: state.employeeLeave.currentYear,
    leaveTypesList: state.leaveTypes.leaveTypesList,
    leaveMasterTypeList: state.employeeLeave.leaveMasterTypeList,

    employeeLeavesSummary: state.leaveReporting.employeeLeavesSummary,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMyTeamEmployeeLeaveList: getMyTeamEmployeeLeaveList,

      getUser: getUser,
      initDoc: initDoc,
      getDocument: getDocument,
      downloadFile: downloadFile,

      getHolidays: getHolidays,

      updateLeave: updateLeave,
      getNextYearLeaveDependOnId: getNextYearLeaveDependOnId,
      closeLeaveByIdData: closeLeaveByIdData,
      getLeaveTypes: getLeaveTypes,
      getLeaveMasterTypesList: getLeaveMasterTypesList,
      updateLeaveDetail: updateLeaveDetail,

      getEmployeeLeavesSummary: getEmployeeLeavesSummary,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveApplicationsContainer);
