import React, { useState, useEffect } from "react";
import moment from "moment";
// import { MdCall } from "react-icons/md";
import { AiOutlineArrowDown } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ButtonView, TextArea, CircularButtonView } from "../../../controls";
import { DataTable } from "../../../common";
import { AiFillEye } from "react-icons/ai";
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
} from "../../../../constants/env";
import { FiEdit3 } from "react-icons/fi";
import EditLeaveFormModal from "./EditLeaveFormModal";
const Unapproved = ({
  data,
  user,
  initDoc,
  getDocument,
  userDocument,
  updateLeave,
  downloadFile,
  onsetLeaveId,
  leaveId,
  // showNextYearLeave,
  getNextYearLeaveDependOnId,
  currentYear,
  leaveMasterTypeList,
  leaveTypesList,
  updateLeaveDetail,
  getEmployeeLeavesSummary,
  employeeLeavesSummary,
}) => {
  const newData = data.filter((item) => {
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    if (new Date(item.startDate) < now) {
      return false;
    } else return true;
  });

  const masterLeaveOptions = leaveMasterTypeList.map((leave) => {
    return {
      value: leave.description,
      label: leave.description,
    };
  });

  const LeaveSubTypeOptions = leaveTypesList.map((leave) => {
    return {
      value: leave._id,
      label: leave.description,
      id: leave._id,
    };
  });

  //leave calculation

  let totalLeavesAvailable =
    employeeLeavesSummary?.length > 0
      ? (
          employeeLeavesSummary[0]?.accumulatedLeaves -
          employeeLeavesSummary[0]?.leavesTaken
        )?.toFixed(1)
      : 0;

  let formatted = Math.abs(totalLeavesAvailable).toString().padStart(2, "0");

  //

  const [rejectOpen, setRejectOpen] = useState(false);
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [editLeaveDetailsShow, setEditLeaveDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);
  const [rejectionVal, setRejectionVal] = useState("");
  const [rejectionId, setRejectionId] = useState("");
  const [errors, setErrors] = useState({});
  const [editLeaveData, setEditLeaveData] = useState({
    leaveSubType: "",
    lastUpdatedBy: "",
    masterLeaveType: "",
    leaveType: "",
    startDate: "",
    endDate: "",
    toalDays: "",
    file: null,
  });

  // It will open the application depend on query

  const handleViewOpenDependOnId = React.useCallback(
    (row) => {
      setViewDetails(row);
      setViewDetailsShow(true);
      if (row.attachementDocumentId) {
        getDocument(row.employeeId[0], true, false, row.attachementDocumentId);
      }
    },
    [getDocument]
  );

  useEffect(() => {
    if (leaveId) {
      const leaveDependOnId = data.find((list) => {
        return list._id === leaveId;
      });
      if (leaveDependOnId) {
        handleViewOpenDependOnId(leaveDependOnId);
      } else {
        getNextYearLeaveDependOnId(leaveId);
      }
    }
    onsetLeaveId(0);
  }, [
    handleViewOpenDependOnId,
    leaveId,
    data,
    onsetLeaveId,
    getNextYearLeaveDependOnId,
  ]);

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-warning-transparent">Pending Approval</span>
      ),
    },
    {
      name: "Type",
      selector: "masterLeaveType",
      center: true,
      grow: 4,
    },
    {
      name: "FD/1H/2H",
      selector: "leaveSubType",
      center: true,
      grow: 4,
    },
    {
      name: "Employee",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {row.userId[0].firstName} {row.userId[0].lastName.slice(0, 1)}
        </span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "70px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <AiFillEye
            className="view"
            size={18}
            onClick={() => {
              handleViewOpen(row);
              if (row.attachementDocumentId) {
                getDocument(
                  row.employeeId[0],
                  true,
                  false,
                  row.attachementDocumentId
                );
              }
              // handleViewOpen()
            }}
          />
          <FiEdit3
            size={17}
            className="edit"
            onClick={() => {
              handleOpen(row);
              if (row.attachementDocumentId) {
                getDocument(
                  row.employeeId[0],
                  true,
                  false,
                  row.attachementDocumentId
                );
              }
            }}
          />
        </div>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "200px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => handleApproveLeave(row)}
              title={"Approve"}
              size="small"
            />
          </div>
          <ButtonView
            onClick={() => handleRejectOpen(row)}
            title={"Reject"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleRejectOpen = (row) => {
    setRejectionId(row._id);
    setRejectOpen(true);
  };
  const handleViewOpen = (row) => {
    console.log("row", row);
    setViewDetails(row);
    setViewDetailsShow(true);
    const data = {
      employeeId: row.employeeId[0],
      year: new Date().getFullYear(),
    };
    getEmployeeLeavesSummary(data);
    // console.log(row)
  };

  const handleApproveLeave = (row) => {
    if (window.confirm("Approve leave?")) {
      updateLeave(
        row._id,
        user,
        "",
        LEAVE_STATUS_APPROVED,
        "Successfully approved leave.",
        currentYear
      );
      handleClose();
    }
  };

  const handleRejectLeave = () => {
    var rules = {
      required: ["rejectionVal"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ rejectionVal }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      updateLeave(
        rejectionId,
        user,
        rejectionVal,
        LEAVE_STATUS_REJECTED,
        "Successfully rejected leave.",
        currentYear
      );
      handleClose();
    }
  };

  const handleOpen = (data) => {
    const leaveDetails = {
      id: data._id,
      leaveSubType: data.leaveSubType,
      masterLeaveType: data.masterLeaveType,
      leaveType: data.leaveType._id,
      startDate: data.startDate,
      endDate: data.endDate,
      approver: data.reportingManagerName,
      comments: data.comments,
      totalDays: data.totalDays,
    };
    setEditLeaveData(leaveDetails);
    setEditLeaveDetailsShow(true);
  };

  const handleClose = () => {
    setViewDetailsShow(false);
    setEditLeaveDetailsShow(false);
    setRejectOpen(false);
    initDoc();
    setRejectionId("");
    setErrors({});
    setRejectionVal("");
    setViewDetails(null);
  };

  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={newData}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>

      <div className="reject_modal">
        <Modal
          show={rejectOpen}
          onHide={handleClose}
          aria-labelledby="reject_modal"
        >
          <Modal.Header>
            <Modal.Title id="reject_modal">Rejection Reason</Modal.Title>
            <div onClick={handleClose} style={{ cursor: "pointer" }}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <TextArea
                name="comments"
                placeholder="Reason..."
                label="Kindly provide the reason for rejecting the leave."
                value={rejectionVal}
                type="textarea"
                setFocus={1}
                onChange={(e) => setRejectionVal(e.target.value)}
                size="fluid"
                error={errors["rejectionVal"]}
                isrequired={true}
              />
            </div>
            <Row>
              <Col md={12} lg={6} className="mb-2">
                <ButtonView
                  onClick={handleClose}
                  title={"Cancel"}
                  styles="danger-outline"
                />
              </Col>
              <Col md={12} lg={6}>
                <ButtonView
                  onClick={handleRejectLeave}
                  title={"Submit Rejection"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>

      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
              <div className="comments ms-1 d-flex justify-content-between align-items-center flex-wrap">
                <h5>
                  Applier:{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.userId[0].firstName}{" "}
                    {viewDetails.userId[0].lastName}
                  </span>
                </h5>
                {viewDetails.masterLeaveType === "Optional Holiday Leave" && (
                  <h5>
                    <span
                      style={{ fontSize: "15px" }}
                      className="badge badge-success"
                    >
                      Optional Holiday
                    </span>
                  </h5>
                )}
              </div>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{viewDetails.leaveSubType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{viewDetails.masterLeaveType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{viewDetails.leaveType.description}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(viewDetails.startDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(viewDetails.endDate)).format("DD/MM/YYYY")}
                  </span>
                </li>
                <div className="mt-2">
                  <h5>Leave summary</h5>

                  {employeeLeavesSummary?.length > 0 ? (
                    <div
                      style={{
                        border: "1px solid #dee2e6",
                        padding: " 0.5rem 1rem",
                        borderRadius: "6px",
                      }}
                    >
                      <p className="mb-1 d-flex justify-content-between">
                        <span>Total Leaves Accumulated</span>
                        <span>
                          {employeeLeavesSummary[0].accumulatedLeaves?.toString()
                            .length > 1
                            ? `${employeeLeavesSummary[0].accumulatedLeaves}`
                            : `0${employeeLeavesSummary[0].accumulatedLeaves}`}
                        </span>
                      </p>
                      <hr style={{ margin: "4px 0px", opacity: "0.1" }} />
                      <p className="mb-1 d-flex justify-content-between">
                        <span>Total Leaves Applied</span>
                        <span>
                          {employeeLeavesSummary[0].leavesTaken?.toString()
                            .length > 1
                            ? `${employeeLeavesSummary[0].leavesTaken}`
                            : `0${employeeLeavesSummary[0].leavesTaken}`}
                        </span>
                      </p>
                      <hr style={{ margin: "4px 0px", opacity: "0.1" }} />
                      <p className="mb-1 d-flex justify-content-between">
                        <span>Sandwich Leaves Taken</span>
                        <span>
                          {employeeLeavesSummary[0].totalSandwich?.toString()
                            .length > 1
                            ? `${employeeLeavesSummary[0].totalSandwich}`
                            : `0${employeeLeavesSummary[0].totalSandwich}`}
                        </span>
                      </p>
                      <hr style={{ margin: "4px 0px", opacity: "0.1" }} />

                      <p className="mb-1 d-flex justify-content-between">
                        <span>Total Leaves Available</span>
                        <span>
                          <span
                            style={
                              totalLeavesAvailable > 0
                                ? { color: "green" }
                                : totalLeavesAvailable < 0
                                ? { color: "red" }
                                : {}
                            }
                          >
                            {totalLeavesAvailable >= 0
                              ? formatted
                              : `-${formatted}`}
                          </span>
                        </span>
                      </p>
                    </div>
                  ) : (
                    <text>This employee is not yet confirmed</text>
                  )}
                </div>
                {viewDetails.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h6>Comments:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={viewDetails.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
                {userDocument ? (
                  <div className="d-flex justify-content-end mt-3">
                    <p className="mt-2">Download file</p>
                    <CircularButtonView
                      Icon={AiOutlineArrowDown}
                      onClick={() => {
                        downloadFile(userDocument.employeeId, userDocument._id);
                      }}
                      size={20}
                    />
                  </div>
                ) : (
                  ""
                )}
                <Row className="mt-3">
                  <Col md={12} lg={6} className="mb-2">
                    <ButtonView
                      onClick={() => handleApproveLeave(viewDetails)}
                      title={"Approve"}
                      styles="secondary"
                    />
                  </Col>
                  <Col md={12} lg={6}>
                    <ButtonView
                      onClick={() => {
                        setViewDetailsShow(false);
                        handleRejectOpen(viewDetails);
                      }}
                      title={"Reject"}
                      styles="danger-outline"
                    />
                  </Col>
                </Row>
              </ul>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {editLeaveDetailsShow && (
        <div className="edit_modal">
          <EditLeaveFormModal
            editLeaveDetailsShow={editLeaveDetailsShow}
            handleClose={handleClose}
            data={newData}
            editLeaveData={editLeaveData}
            setEditLeaveData={setEditLeaveData}
            masterLeaveOptions={masterLeaveOptions}
            LeaveSubTypeOptions={LeaveSubTypeOptions}
            userDocument={userDocument}
            downloadFile={downloadFile}
            updateLeaveDetail={updateLeaveDetail}
          />
        </div>
      )}
    </>
  );
};

export default Unapproved;
